<template lang="html">
  <PillarOverviewGrid v-if="data">
    <PillarOverviewTile
      pillar="management"
      route="risk"
    >
      <CompletedStatusLine :status="data.risk != null ? 'finished': 'empty'" />
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="management"
      route="compliance"
    >
      <CompletedStatusLine :status="data.compliance != null ? 'finished': 'empty'" />
    </PillarOverviewTile>
  </PillarOverviewGrid>
</template>

<script>
import PillarOverviewGrid from './widgets/PillarOverviewGrid'
import PillarOverviewTile from './widgets/PillarOverviewTile'
import CompletedStatusLine from './widgets/CompletedStatusLine'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    PillarOverviewGrid,
    PillarOverviewTile,
    CompletedStatusLine
  },
  mixins: [
    ApiMixin
  ],
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/state/management/${this.$route.params.year}`
    },
    detailRoutes () {
      return [
        'Risikomanagement',
        'Compliance'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
